$enable-grid-classes: true;
$enable-cssgrid: false;

@import "../node_modules/bootstrap/scss/bootstrap-grid";
@import "theme.scss";

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Kanit', sans-serif;
  background-color: var(--rex-background-color-2);
  overflow-x: hidden;
}

.gap-0 {
  gap: 0;
}

.gap-4 {
  gap: 0.25rem;
}

.gap-6 {
  gap: 0.375rem;
}

.gap-8 {
  gap: 0.5rem;
}

.gap-10 {
  gap: 10px;
}

.gap-16 {
  gap: 10px;
}

.gap-32 {
  gap: 10px;
}

.pt-8 {
  padding-top: 0.5rem;
}

.pb-8 {
  padding-bottom: 0.5rem;
}

.py-8 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.mt-8 {
  margin-top: 0.5rem;
}

.mb-8 {
  margin-bottom: 0.5rem;
}

.mt-10 {
  margin-top: 0.625rem;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

.mt-12 {
  margin-top: 0.75rem;
}

.mb-12 {
  margin-bottom: 0.75rem;
}

.mt-16 {
  margin-top: 1rem;
}

.mb-16 {
  margin-bottom: 1rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

.mb-20 {
  margin-bottom: 1.25rem;
}

.mt-24 {
  margin-top: 1.5rem;
}

.mb-24 {
  margin-bottom: 1.5rem;
}

.mt-40 {
  margin-top: 2.5rem;
}

.mb-40 {
  margin-bottom: 2.5rem;
}

.mr-auto {
  margin-right: auto !important;
}

.fz-11 {
  font-size: 11px !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center !important;
}

.icon-10 {
	width: 10px !important;
	height: 10px !important;
	font-size: 10px !important;
	line-height: 10px !important;
}

.icon-12 {
	width: 12px !important;
	height: 12px !important;
	font-size: 12px !important;
	line-height: 12px !important;
}

.icon-14 {
	width: 14px !important;
	height: 14px !important;
	font-size: 14px !important;
	line-height: 14px !important;
}

.icon-16 {
	width: 16px !important;
	height: 16px !important;
	font-size: 16px !important;
	line-height: 16px !important;
}

.icon-20 {
	width: 20px !important;
	height: 20px !important;
	font-size: 20px !important;
	line-height: 20px !important;
}

.icon-32 {
	width: 32px !important;
	height: 32px !important;
	font-size: 32px !important;
	line-height: 32px !important;
}

.icon-48 {
	width: 48px !important;
	height: 48px !important;
	font-size: 48px !important;
	line-height: 48px !important;
}

.background-color-1 {
  background-color: var(--rex-background-color-1) !important;
}

.text_ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 0;
}

.vertical-align-top {
  vertical-align: top !important;
}

.rex-title {
  color: var(--rex-primary-color-700);
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}

.rex-panel-title {
  color: var(--rex-black-color-700);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

//PROFILE
.user-menu {
  margin-top: 0.75rem !important;
}

//MENU TOOLTIP
.menu-tooltip {
  margin-left: 1.313rem;

  .mdc-tooltip__surface {
    background-color: var(--rex-background-color-1);
    color: var(--rex-primary-color-900);
    padding: 0.625rem 0.75rem;
    min-width: 7rem;
    font-family: 'Kanit', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    border-radius: 6px;
    box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.05);
  }
}
// SUB MENU
.custom-sub-menu {
  --mat-menu-item-label-text-line-height: 16px;
  --mat-menu-item-label-text-weight: 400;
  margin-left: 78px;

  .mat-mdc-menu-content {
    padding: 0;
  }
}

//SWEET-ALET
.swal2-container.swal2-bottom>.swal2-popup {
  box-shadow: none !important;
}

.snackbar-info {
  border-radius: 8px;
  border: var(--rex-toastr-color-blue-1) 1px solid !important;

  .mat-mdc-snack-bar-container,
  .mdc-snackbar__surface,
  .mdc-snackbar__label,
  .mdc-button__label,
  a {
    background-color: var(--rex-toastr-color-blue-2) !important;
    color: var(--rex-toastr-color-blue-1) !important;
    border-radius: 8px !important;
  }

  .toast-title .toast-text {
    color: var(--rex-toastr-color-blue-1) !important;
  }
}

.snackbar-warning {
  border-radius: 8px;
  border: var(--rex-toastr-color-yellow-1) 1px solid !important;
  background-color: var(--rex-toastr-color-yellow-2) !important;
  color: var(--rex-toastr-color-yellow-1) !important;

  .mat-mdc-snack-bar-container,
  .mdc-snackbar__surface,
  .mdc-snackbar__label,
  .mdc-button__label,
  a {
    background-color: var(--rex-toastr-color-yellow-2) !important;
    color: var(--rex-toastr-color-yellow-1) !important;
    border-radius: 8px !important;
  }

  .toast-title .toast-text {
    color: var(--rex-toastr-color-yellow-1) !important;
  }
}

.snackbar-success {
  border-radius: 8px;
  border: var(--rex-toastr-color-green-1) 1px solid !important;
  background-color: var(--rex-toastr-color-green-2) !important;
  color: var(--rex-toastr-color-green-1) !important;

  .mat-mdc-snack-bar-container,
  .mdc-snackbar__surface,
  .mdc-snackbar__label,
  .mdc-button__label,
  a {
    background-color: var(--rex-toastr-color-green-2) !important;
    color: var(--rex-toastr-color-green-1) !important;
    border-radius: 8px !important;
  }

  .toast-title .toast-text {
    color: var(--rex-toastr-color-green-1) !important;
  }
}

.snackbar-error {
  border-radius: 8px;
  border: var(--rex-toastr-color-red-1) 1px solid !important;
  background-color: var(--rex-toastr-color-red-3) !important;
  color: var(--rex-toastr-color-red-2) !important;

  .mat-mdc-snack-bar-container,
  .mdc-snackbar__surface,
  .mdc-snackbar__label,
  .mdc-button__label,
  a {
    background-color: var(--rex-toastr-color-red-3) !important;
    color: var(--rex-toastr-color-red-2) !important;
    border-radius: 8px !important;
  }

  .toast-title .toast-text {
    color: var(--rex-toastr-color-red-2) !important;
  }
}

//Tenant color
.ellipsis {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: gray;

  @include apply-color($rex-tenant, 'background-color');
}

// Status color
.order-status-wrapper,
.request-status-wrapper,
.request-approval-status-wrapper,
.category-status-wrapper {
  border-radius: 16px;
  background-color: var(--rex-primary-color-100);
  color: var(--rex-primary-color-900);
  padding: 4px 8px;
  width: fit-content;
}

.order-status-wrapper {
  @include apply-color($rex-order-status-bg, 'background-color');
  @include apply-color($rex-order-status-color, 'color');
}

.request-status-wrapper {
  @include apply-color($rex-request-status-bg, 'background-color');
  @include apply-color($rex-request-status-color, 'color');
}

.request-approval-status-wrapper {
  @include apply-color($rex-request-approval-status-bg, 'background-color');
  @include apply-color($rex-request-approval-status-color, 'color');
}

.category-status-wrapper {
  @include apply-color($rex-category-status-bg, 'background-color');
  @include apply-color($rex-category-status-color, 'color');
}

.order-status-wrapper span,
.request-status-wrapper span,
.request-approval-status-wrapper span,
.category-status-wrapper span {
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  text-transform: uppercase;
}

//timer sla
.box-timer-sla {
  border-radius: 20px;
  background: var(--rex-accent-color-100);
  color: var(--rex-accent-color-300);
  padding: 4px 8px 4px 4px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  @include apply-color($bg-timer-sla, 'background');
  @include apply-color($color-timer-sla, 'color');
}

.timer-sla {
  color: var(--rex-accent-color-300);
  @include apply-color($icon-timer-sla, 'color');
}

// USER CHIP
.user__chip {
  background-color: var(--rex-secondary-color-300);
  border: 1px solid var(--rex-background-color-1);
  border-radius: 50%;
  color: var(--rex-background-color-1);
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-size: 13.333px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }
}
//ARIA-MAT-SELECT
mat-icon {
  @include apply-color($rex-tenant, 'fill', 'svg');
  @include apply-color($rex-sla-status, 'fill', 'svg');
}

//TABLE
.mat-mdc-table .mdc-data-table__header-row {
  height: 32px;
}

.mat-mdc-table thead .mat-mdc-header-row {
  .mat-mdc-header-cell {
    background-color: var(--rex-primary-color-400);
    padding: 0.5rem;
    color: var(--rex-primary-color-900) !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  }

  .mat-mdc-header-cell:first-child {
    border-top-left-radius: 4px;
  }

  .mat-mdc-header-cell:last-child {
    border-top-right-radius: 4px;
  }
}

.mat-mdc-table .mdc-data-table__row {
  height: 48px;
}

.mat-mdc-table .mdc-data-table__row .mdc-data-table__cell {
  padding: 0.5rem;
  color: var(--rex-black-color-700);
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  border-bottom: 1px solid var(--rex-black-color-50);
}

.mat-mdc-table .mdc-data-table__row:nth-child(odd) {
  .mdc-data-table__cell {
    background-color: var(--rex-black-color-50);
  }
}

.mat-mdc-table .mdc-data-table__row:nth-child(even) {
  .mdc-data-table__cell {
    background-color: var(--rex-background-color-1);
  }
}

.mat-mdc-table .mdc-data-table__row:last-child {
  .mdc-data-table__cell:first-child {
    border-bottom-left-radius: 4px;
  }

  .mdc-data-table__cell:last-child {
    border-bottom-right-radius: 4px;
  }
}

//STEPPER NEW-REQUEST
.new-request-stepper {
  &.mat-stepper-horizontal {
    display: flex !important;
  }

  &.mat-stepper-horizontal, 
  &.mat-stepper-vertical {
    background-color: inherit;
  }

  .mat-horizontal-stepper-header-container {
    display: none;
  }

  .mat-horizontal-stepper-wrapper {
    flex-grow: 1;
    max-width: 100%;
  }

  .mat-horizontal-content-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }

  .mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) {
    height: 100%;
  }

  .mat-horizontal-stepper-content.mat-horizontal-stepper-content-inactive {
    display: none;
  }
}

//EXPANSION PANEL
.rex-expansion-panel {
  .mat-expansion-indicator::after {
    color: var(--rex-primary-color-500);
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}

.rex-slide-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  min-height: 48px;

  &--label, 
  &--option {
    color: var(--rex-black-color-700);
    font-size: 10px;
    font-style: normal;
    line-height: 16px;
  }

  &--label {
    font-weight: 400;
  }

  &--option {
    font-weight: 300;
  }

  &--group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;   
  }
}

.tooltip-rules,
.tooltip-items {
  .mdc-tooltip__surface {
    border-radius: 4px;
    background: var(--rex-black-color-800);
    padding: 0.5rem;
  
    color: var(--rex-background-color-1);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
  }

  &.mdc-tooltip--shown .mdc-tooltip__surface-animation {
    opacity: 0.8;
  }
}

.tooltip-rules .mdc-tooltip__surface {
  min-width: 288px;
}
.mat-mdc-progress-spinner.white-spinner {
  --mdc-circular-progress-active-indicator-color: white;
}

//REX DETAIL TABLE
.content__table {
  border-collapse: separate;
  border-spacing: 2px;
  width: 100%;

  &__group {
    &:first-child .content__table__column {
      border-top-left-radius: 4px;
    }
    &:last-child .content__table__column {
      border-bottom-left-radius: 4px;
    }
    .content__table__column:first-child {
      width: 10rem;
    }
  }

  &__column {
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    color: var(--rex-black-color-700);
    padding: 8px 16px;
    border-bottom: 1px solid var(--rex-black-color-50);
    vertical-align: middle;

    &--blue {
      background: var(--rex-primary-color-100);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; 
      color: var(--rex-primary-color-900);
      border-bottom: none;
    }
  }
}

//ELEMENTS
.rex_link {
  color: var(--rex-primary-color-700);
  text-decoration-line: underline;
  cursor: pointer;
}

.box__tag {
  display: block;
  border-radius: 2px;
  background: var(--rex-primary-color-700);
  color: var(--rex-background-color-1);
  padding: 0 4px;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
  width: fit-content;
}

@media (min-width: 992px) {
  /* Define las columnas de 1 a 18 */
  .col-1-18 { width: calc(100% / 18 * 1); }
  .col-2-18 { width: calc(100% / 18 * 2); }
  .col-3-18 { width: calc(100% / 18 * 3); }
  .col-4-18 { width: calc(100% / 18 * 4); }
  .col-5-18 { width: calc(100% / 18 * 5); }
  .col-6-18 { width: calc(100% / 18 * 6); }
  .col-7-18 { width: calc(100% / 18 * 7); }
  .col-8-18 { width: calc(100% / 18 * 8); }
  .col-9-18 { width: calc(100% / 18 * 9); }
  .col-10-18 { width: calc(100% / 18 * 10); }
  .col-11-18 { width: calc(100% / 18 * 11); }
  .col-12-18 { width: calc(100% / 18 * 12); }
  .col-13-18 { width: calc(100% / 18 * 13); }
  .col-14-18 { width: calc(100% / 18 * 14); }
  .col-15-18 { width: calc(100% / 18 * 15); }
  .col-16-18 { width: calc(100% / 18 * 16); }
  .col-17-18 { width: calc(100% / 18 * 17); }
  .col-18-18 { width: calc(100% / 18 * 18); }
}

//DASHBOARD
.chart-tooltip {
  background-color: var(--rex-black-color-800)!important;
  padding: 3px 5px;
  border-radius: 5px;
  color: var(--rex-background-color-1)!important;
  width: fit-content!important;
  font-family: 'Kanit';
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  z-index: 999;

  p {
      color: var(--rex-background-color-1)!important;
      font-family: 'Kanit';
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
  }
}

.chart-tooltip-circle {
  height: 5px;
  width: 5px;
  display: inline-block;
  border-radius: 50%;
}

[hidden] {
  visibility: hidden;
  height: 0 !important;
  flex-grow: 0 !important;
  overflow: hidden !important;
}

.display-none {
  display: none !important;
}