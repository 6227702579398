@use '@angular/material' as mat;
@import 'mixins';

@include mat.core();

$rex-warn: (
  50 : #fdebeb,
  100 : #f9cccc,
  200 : #f6abab,
  300 : #f28989,
  400 : #ef6f6f,
  500 : #ec5656,
  600 : #ea4f4f,
  700 : #e74545,
  800 : #e43c3c,
  900 : #df2b2b,
  A100 : #ffffff,
  A200 : #ffebeb,
  A400 : #ffb8b8,
  A700 : #ff9f9f,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$rex-accent: (
  50: #F7F7F7,
  100: #EEEEEE,
  200: #E2E2E2,
  300: #D0D0D0,
  400: #ABABAB,
  500: #8A8A8A,
  600: #636363,
  700: #505050,
  800: #323232,
  900: #000000,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$rex-primary: (
  50 : #F2F4FC,
  100 : #E2E7F7,
  200 : #CBD5F2,
  300 : #A8BBE8,
  400 : #7E98DC,
  500 : #637AD2,
  600 : #4C5DC4,
  700 : #424DB3,
  800 : #3B4092,
  900 : #333975,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$rex-black: (
  50: #F7F7F7,
  100: #EEEEEE,
  200: #E2E2E2,
  300: #D0D0D0,
  400: #ABABAB,
  500: #8A8A8A,
  600: #636363,
  700: #505050,
  800: #323232,
  900: #000000,
);

$rex-secondary: (
  50: #FFF7ED,
  100: #FFEED4,
  200: #FFD8A9,
  300: #FFB665,
  400: #FE9539,
  500: #FC7613,
  600: #ED5A09,
  700: #C54309,
  800: #9C3510,
  900: #7E2E10,
);

// Semantic colors
$rex-semantic: (
  'red': map-get($rex-warn, 500),
  red-dark: #742A2A,
  red-light: #FAD5D5,
  'orange': #EC8E4B,
  orange-dark: #744525,
  orange-light: #FAE3D2,
  'yellow': #F8C953,
  yellow-dark: #7A6329,
  yellow-light: #FDF1D4,
  'green': #51D36E,
  green-dark: #317F42,
  green-light: #B9EDC5,
  green-light-2: #81DCB0,
  green-light-3: #E3F1EA,
  green-light-4: #2BA168,
  green-light-5: #FEFCFC99,
  green-light-6: #00B8A2,
  green-light-7: #E4F2F0,
  green-light-8: #13543A,
  green-light-9: #F6FFFE,
  green-light-10: #E9FFEA,
  'blue': #459FE0,
  blue-dark: #224E6E,
  blue-light: map-get($rex-accent, 900),
  'purple': #A067E8,
  purple-dark: #4E3271,
  purple-light: #E7D9F9,
  'pink': #E74DBC,
  pink-dark: #71265C,
  pink-dark-2: #AA279D,
  pink-light: #F9D3EE,
  pink-light-2: #FDEAFB,
  black-1: #223345
);

$rex-system: (
  primary: map-get($rex-primary, 500),
  accent: map-get($rex-accent, 500),
  warning: map-get($rex-warn, 500),
  success: #43A047,
  alert: map-get($rex-semantic, 'yellow'),
);

// Background colors
$rex-background: (
  1: #FFFFFF,
  2: #F6F8FC,
);

// Toastr colors
$rex-toastr: (
  red-1: map-get($rex-warn, 500),
  red-2: #F44336,
  red-3: #FFEBEE,
  yellow-1: #FF9800,
  yellow-2: #FFF3E0,
  green-1: map-get($rex-system, success),
  green-2: #E8F5E9,
  blue-1: #2196F3,
  blue-2: #E3F2FD,
);

// Misc colors
$rex-misc: (
  sky-blue: #EFF8FF,
  'green': #017466,
  green-with-opacity: #81dcb04d,
  red-4: #FFC5C5,
  red-5: #FDEBEA,
  red-6: #AA273C,
  yellow-1: #FFEDCA,
  yellow-2: #C78B1A,
  brown-1: #FFE2C8,
  brown-2: #AA6627,
  'blue': map-get($rex-primary, 500),
  blue-2: #EBF0FE,
  blue-3: #174F9A,
  button-microsoft: #2F2F2F,
  notification: #E4F7EC,
  'gray': map-get($rex-accent, 500),
);

$rex-tenant: (
  AGORA: #02B1FF,
  AGORA_CLUB: #F2007C,
  AGORA_PAY: #00CAFF,
  AGORA_SHOP: #FF6000,
  AGORA_AHORRA: #001FFE,
  MKR: #FFE500,
  TPSA: #FF4949,
  PVEA: #FF4949,
  HPSA: #F59237,
  RPLAZA: #7B61FF,
  SHPSTAR: #0970CC,
  VIV: #168D7F,
  JOKR: #063F71,
);

$rex-order-status-bg: (
  DELIVERED: #B8EBAD,
  CANCELLED: #FACBCB,
);

$rex-order-status-color: (
  DELIVERED: #43A047,
  CANCELLED: #EC5656,
);

$rex-request-status-bg: (
  PENDING_PHOTOS: #CBECFA,
  PENDING: #FACECB,
  IN_REVIEW: #E0CBFA,
  COMPLETED: #CBFACD,
  CANCELLED: #EAEAEA
);

$rex-request-status-color: (
  PENDING_PHOTOS: #459FE0,
  PENDING: #EC5656,
  IN_REVIEW: #A067E8,
  COMPLETED: #43A047,
  CANCELLED: #636363
);

$rex-request-approval-status-bg: (
  APPROVE: #FAEACB,
  REJECT: #FACECB
);

$rex-request-approval-status-color: (
  APPROVE: #C98F1F,
  REJECT: #EC5656
);

$rex-category-status-bg: (
  PENDING: #FFBFBF,
  CONFIGURED: #B8EBAD,
);

$rex-category-status-color: (
  PENDING: #EC5656,
  CONFIGURED: #43A047,
);

$bg-timer-sla: (
  ON_TIME: #BFF1C1,
  ABOUT_EXPIRE: #FFF9DF,
  EXPIRED: #FFE7E7
);

$color-timer-sla: (
  ON_TIME: #43A047,
  ABOUT_EXPIRE: #F4BE33,
  EXPIRED: #EC5656
);

$icon-timer-sla: (
  ON_TIME: #43A047,
  ABOUT_EXPIRE: #F8C953,
  EXPIRED: #EC5656
);

$rex-sla-status: (
  ON_TIME: #43A047,
  ABOUT_EXPIRE: #F8C953,
  EXPIRED: #EC5656
);

// Referencias de Colores
:root {
  @include generate-color-variables('primary', $rex-primary);
  @include generate-color-variables('accent', $rex-accent);
  @include generate-color-variables('warn', $rex-warn);
  @include generate-color-variables('black', $rex-black);
  @include generate-color-variables('secondary', $rex-secondary);
  @include generate-color-variables('semantic', $rex-semantic);
  @include generate-color-variables('system', $rex-system);
  @include generate-color-variables('background', $rex-background);
  @include generate-color-variables('toastr', $rex-toastr);
  @include generate-color-variables('misc', $rex-misc);
  @include generate-color-variables('tenant', $rex-tenant);
  @include generate-color-variables('sla-status', $rex-sla-status);
}

/* Typography */
$headline1: mat.m2-define-typography-level(32px, 24px, 600);
$headline2: mat.m2-define-typography-level(28px, 24px, 600);
$headline3: mat.m2-define-typography-level(20px, 24px, 500);
$headline4: mat.m2-define-typography-level(18px, 24px, 500);
$headline5: mat.m2-define-typography-level(16px, 24px, 500); // H1
$headline6: mat.m2-define-typography-level(16px, 24px, 500); // H2
$subtitle1: mat.m2-define-typography-level(14px, 24px, 300); // H3
$subtitle2: mat.m2-define-typography-level(14px, 24px, 300); // H4
$body1: mat.m2-define-typography-level(14px, 24px, 300); // Base body text
$body2: mat.m2-define-typography-level(14px, 24px, 300);
$body3: mat.m2-define-typography-level(14px, 24px, 300);
$overline: mat.m2-define-typography-level(11px, 24px, 400);
$caption: mat.m2-define-typography-level(12px, 24px, 400);
$button: mat.m2-define-typography-level(14px, 24px, 300);

// Define the typography config.
$rex-typography: mat.m2-define-typography-config($font-family: 'Kanit',
    $headline-1: $headline1,
    $headline-2: $headline2,
    $headline-3: $headline3,
    $headline-4: $headline4,
    $headline-5: $headline5,
    $headline-6: $headline6,
    $body-1: $body1,
    $body-2: $body2,
    $subtitle-1: $subtitle1,
    $subtitle-2: $subtitle2,
    $button: $button,
    $caption: $caption,
    $overline: $overline);

$rex-web-primary: mat.m2-define-palette($rex-primary);
$rex-web-accent: mat.m2-define-palette($rex-accent, A200, A100, A400);
$rex-web-warn: mat.m2-define-palette($rex-warn);

$rex-web-theme: mat.m2-define-light-theme((color: (
        primary: $rex-web-primary,
        accent: $rex-web-accent,
        warn: $rex-web-warn,
      ),
      typography: $rex-typography,
      density: -2,
    ));

@include mat.all-component-themes($rex-web-theme);
@include mat.typography-hierarchy($rex-typography);

$scroll-color: var(--rex-black-color-300);
$scroll-background: var(--rex-background-color-1);
$button-height: 48px;

// button default styles
.mdc-button {

  &.mat-mdc-button-base {
    height: $button-height;
  }

  &.mat-primary {

    &.mdc-button--raised,
    &.mdc-button--unelevated {
      color: var(--rex-background-color-1) !important;
      box-shadow: none;

      &:hover {
        background-color: var(--rex-primary-color-600) !important;
      }

      &:disabled {
        background-color: var(--rex-primary-color-100) !important;
      }

      &:active,
      &:focus {
        background-color: var(--rex-primary-color-600) !important;
      }
    }

    &:not(.mdc-button--outlined):disabled {
      color: var(--rex-primary-color-300) !important;
    }

    &.mat-mdc-raised-button {
      &:disabled {
        background-color: #637AD2;
      }
    }

    &.mat-mdc-outlined-button {
      &:not(:disabled) {
        border-color: var(--rex-system-color-primary);
      }
      &:disabled {
        border-color: var(--rex-primary-color-200);
        color: var(--rex-primary-color-200) !important;
      }
    }
  }

  &.mat-warn {
    &.mat-mdc-outlined-button {
      border-color: var(--rex-misc-color-red-7);

      &:disabled {
        border-color: var(--rex-misc-color-red-4);
        color: var(--rex-misc-color-red-4);
      }
    }
  }
}

// form default styles
.mat-mdc-text-field-wrapper {
  background-color: var(--rex-background-color-1) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--invalid, .mdc-text-field--focused) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--invalid, .mdc-text-field--focused) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--invalid, .mdc-text-field--focused) .mdc-notched-outline__trailing {
  border-color: var(--rex-black-color-100) !important;
}

mat-select {
  background-color: inherit;
  border-radius: 5px;
  display: inline-block;
  width: 100%;
  outline: none;
  font-size: var(--mdc-typography-subtitle1-font-size, 14px) !important;

  .mat-mdc-select-trigger {
    .mat-mdc-select-value {
      color: var(--rex-accent-color-500);

      .mat-mdc-select-placeholder {
        color: var(--rex-accent-color-300);
      }
    }

    mat-select-trigger.has-plus {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .display-label {
        //flex: 1;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .display-plus {
        text-align: right;
        //width: 10%;
        margin-left: 3px;
      }
    }

    mat-icon {
      &.icon-xs {
        height: 8px;
        width: 8px;
        font-size: 8px;
        line-height: 8px;
        margin-right: 10px;
      }

      &.icon-medium {
        height: 12px;
        width: 12px;
        font-size: 12px;
        line-height: 12px;
        margin-right: 10px;
      }
    }
  }

  .mat-mdc-select-arrow-wrapper {
    .mat-mdc-select-arrow {
      display: none;
    }

    &::after {
      content: "expand_more";
      display: inline-block;
      font-family: 'Material Icons';
      font-size: 16px;
      margin-right: -3px;
      padding-left: 5px;
      color: var(--rex-accent-color-300);
    }
  }

  .mdc-line-ripple {
    display: none;
  }

  &.mat-mdc-select-disabled {
    .mat-mdc-select-trigger {
      .mat-mdc-select-value {
        color: var(--rex-black-color-500);
      }
    }

    .mat-mdc-select-arrow-wrapper {
      &::after {
        color: var(--rex-black-color-300);
      }
    }
  }
}

.mat-select-chip .mat-mdc-select-arrow-wrapper::after {
  display: none; /* Oculta el ícono de flecha por defecto */
}

.aria-custom-select,
.aria-custom-select-fit {
  .mat-mdc-optgroup {
    .mat-mdc-optgroup-label {
      min-height: 36px;
      span.mdc-list-item__primary-text {
        color: var(--rex-black-color-400);
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        text-transform: uppercase;
      }
    }
    .mat-mdc-option:not(.mat-mdc-option-multiple) {
      padding-left: 16px;
    }
  }
}
%mat-form-field-icon-prefix-styles {
  .mat-mdc-text-field-wrapper {
    padding-left: 0;
    --mat-mdc-form-field-label-offset-x: 28px;
  }

  .mat-mdc-floating-label {
    transform: var(--mat-mdc-form-field-label-transform, translateY(-50%) translateX(calc(1 * (var(--mat-mdc-form-field-label-offset-x, 0px))))) !important;
  }

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    color: var(--rex-accent-color-300);
    padding: 0;

    mat-icon {
      height: 20px;
      width: 20px;
      font-size: 20px;
      line-height: 20px;
      margin-right: 0;
    }
  }

  &.mat-focused {
    .mat-mdc-form-field-icon-prefix {
      color: var(--rex-system-color-primary);
    }
  }

  &.mat-form-field-invalid {
    .mat-mdc-form-field-icon-prefix {
      color: var(--rex-warn-color-500);
    }
  }
}

%mat-form-field-icon-suffix-styles {

  .mat-mdc-form-field-icon-suffix,
  .mat-datepicker-toggle {
    color: var(--rex-accent-color-300);
    padding: 0;

    mat-icon {
      height: 20px;
      width: 20px;
      font-size: 20px;
      line-height: 20px;
      margin-right: 0;
    }
  }

  &.mat-focused {
    .mat-mdc-form-field-icon-suffix,
    .mat-datepicker-toggle {
      color: var(--rex-system-color-primary);
    }
  }

  &.mat-form-field-invalid {
    .mat-mdc-form-field-icon-suffix,
    .mat-datepicker-toggle {
      color: var(--rex-warn-color-500);
    }
  }
}

mat-pseudo-checkbox {
  color: var(--rex-black-color-200) !important;
  height: 16px !important;
  width: 16px !important;
  border-radius: 2.5px !important;
}

.mat-mdc-menu-panel {
  .mat-mdc-menu-item:not(:last-child) {
    border-bottom: 1px solid var(--rex-semantic-color-green-light-3) !important;
  }

  span.mdc-list-item__primary-text {
    color: var(--rex-misc-color-blue) !important;
    width: 100% !important;
  }

}

.mat-mdc-select-panel {  
  border: 1px solid var(--rex-primary-color-500);
  border-radius: 4px !important;
  padding: 0px !important;

  mat-option {

    &.mat-mdc-option {
      border-bottom: none;

      span.mdc-list-item__primary-text {
        color: var(--rex-primary-color-400) !important;
        width: 100% !important;
      }

      .mat-pseudo-checkbox-checked {
        display: none;
      }

      &.mat-mdc-option-multiple {
        .mat-pseudo-checkbox-checked {
          display: inline-block;
          border-color: var(--rex-primary-color-500) !important;
          background-color: var(--rex-system-color-primary) !important;
        }

        .mat-pseudo-checkbox-full {
          border-color: var(--rex-primary-color-400);
        }
      }

      &.mat-option-button-all-select,
      &.mat-option-button-clean-select {
        .mat-pseudo-checkbox {
          display: none !important;
        }
      }

      &.mat-mdc-option-active {
        background-color: var(--rex-background-color-2) !important;

        span.mdc-list-item__primary-text {
          color: var(--rex-primary-color-700) !important;
        }
      }

      &.mat-option-search-box {
        padding: 3px !important;
        opacity: 1 !important;
        border: none !important;
        min-width: 200px;
        pointer-events: auto;

        .mat-pseudo-checkbox {
          display: none !important;
        }

        .mdc-list-item__primary-text {
          width: 100% !important;
          opacity: 1;

          mat-form-field {
            .mat-mdc-text-field-wrapper {
              background-color: var(--rex-background-color-2) !important;
            }

            mat-icon {
              color: var(--rex-black-color-800);
            }

            &.mat-mdc-form-field-has-icon-prefix {
              @extend %mat-form-field-icon-prefix-styles;
            }

            &.mat-mdc-form-field-has-icon-suffix {
              @extend %mat-form-field-icon-suffix-styles;
            }
          }
        }
      }

      &.mat-option-button-all-select {
        .mat-mdc-button {
          display: flex;
          margin: auto;
        }
      }
      &.mat-option-button-clean-select {
        .mat-mdc-button {
          display: flex;
          margin-left: auto;
        }
      }

      &:not(:hover).mdc-list-item.mdc-list-item--selected {
        background-color: var(--rex-background-color-2);

        .mdc-list-item__primary-text {
          color: var(--rex-primary-color-700) !important;
        }
      }

      mat-icon {
        &.icon-xs {
          height: 8px;
          width: 8px;
          font-size: 8px;
          line-height: 8px;
          margin-right: 8px;
        }

        &.icon-medium {
          height: 16px;
          width: 16px;
          font-size: 16px;
          line-height: 16px;
          margin-right: 8px;
        }
      }
    }
  }
}

mat-form-field {
  &.mat-form-field-appearance-outline {
    &.rex-box {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }

      .mat-mdc-form-field-subscript-wrapper:has(.mat-mdc-form-field-error-wrapper) {
        display: flex;
      }
    }

    &.search-box {
      width: 100%;

      .mat-mdc-form-field-icon-prefix,
      .mat-mdc-form-field-icon-suffix {
        color: var(--rex-primary-color-600);
      }

      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }

    &.borderless {
      .mat-mdc-form-field-flex {
        .mdc-notched-outline {

          .mdc-notched-outline__leading,
          .mdc-notched-outline__notch,
          .mdc-notched-outline__trailing {
            border: transparent;
          }
        }
      }
    }

    &.selected-value:not(.mat-form-field-invalid) {
      .mdc-text-field:not(.mdc-text-field--disabled, .mdc-text-field--invalid, .mdc-text-field--focused) {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: var(--rex-primary-color-400) !important;
        }

        .mdc-notched-outline__notch {
          border-left: 0px solid transparent !important;
        }

        .mdc-floating-label {
          color: var(--rex-primary-color-400) !important;
        }

        mat-select {
          .mat-mdc-select-trigger {
            .mat-mdc-select-value {
              color: var(--rex-accent-color-500) !important;
            }
          }

          .mat-mdc-select-arrow-wrapper::after {
            color: var(--rex-primary-color-400) !important;
          }
        }

        .mdc-text-field__input,
        .mat-date-range-input-separator {
          color: var(--rex-accent-color-500) !important;
        }

        .mat-mdc-form-field-icon-prefix {
          color: var(--rex-primary-color-400) !important;
        }

        .mat-mdc-form-field-icon-suffix {
          color: var(--rex-primary-color-400) !important;
        }

        .mat-datepicker-toggle-default-icon {
          color: var(--rex-primary-color-400) !important;
        }
      }
    }

    &.mat-focused {
      .mat-mdc-select-arrow-wrapper::after {
        color: var(--rex-system-color-primary) !important;
      }
    }

    &.mat-form-field-invalid {
      .mat-mdc-select-arrow-wrapper::after {
        color: var(--rex-warn-color-500) !important;
      }
    }
  }

  &.no-icon-prefix-styles {
    .mat-mdc-form-field-icon-prefix {
      padding: 0;
    }
  }

  .mat-mdc-form-field-error-wrapper {
    font-family: 'Kanit';
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
    padding: 0px 10px;
    margin-top: 4px;

    .mat-mdc-form-field-error {
      display: flex;
      align-items: center;
      height: 12px;
    }
  }

  .mdc-text-field__input {
    font-size: var(--mdc-typography-subtitle1-font-size, 14px) !important;
    color: var(--rex-accent-color-500) !important;
    font-weight: 300 !important;
  }

  &.mat-form-field-disabled {
    .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
      color: var(--rex-black-color-300) !important;
    }

    .mat-mdc-floating-label {
      color: var(--rex-black-color-400) !important;
    }

    .mat-mdc-text-field-wrapper {
      background-color: var(--rex-black-color-50) !important;
    }
  }

  &.mat-form-field-invalid {
    .mdc-text-field__input {
      color: var(--rex-accent-color-600) !important;
    }

    .mat-mdc-floating-label {
      &.mdc-floating-label--float-above {
        color: var(--rex-semantic-color-red) !important;
      }
    }
  }

  &:not(.selected-value) {
    .mdc-text-field:not(.mdc-text-field--disabled,
      .mdc-text-field--invalid,
      .mdc-text-field--focused) .mdc-floating-label {
      color: var(--rex-accent-color-300) !important;
    }
  }

  &.mat-mdc-form-field-has-icon-prefix {
    @extend %mat-form-field-icon-prefix-styles;
  }

  &.mat-mdc-form-field-has-icon-suffix {
    @extend %mat-form-field-icon-suffix-styles;
  }

  &.mat-mdc-form-field-type-mat-date-range-input {
    .mat-mdc-form-field-infix {
      width: 100%;
    }

    mat-datepicker-toggle {
      mat-icon {
        font-size: 16px;
        height: 16px;
        width: 16px;
        line-height: 13px;
      }
    }
  }
}

.rex-filters {
  mat-form-field {

    .mdc-text-field__input,
    input::placeholder {
      color: var(--rex-misc-color-blue) !important;
    }
  }

  &.no-input-errors {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

mat-chip {
  border-radius: 10px !important;
  padding: 5px 10px !important;
  width: fit-content !important;

  .mdc-evolution-chip__action--primary {
    padding: 0px !important;
  }

  .mdc-evolution-chip__text-label {
    text-align: center !important;
    font-size: 11.5px !important;
    line-height: 14px !important;
  }

  .mdc-evolution-chip__cell {
    overflow: hidden !important;
  }

  &.mat-chip-blue {
    border: 1px solid var(--rex-misc-color-blue) !important;
    background: var(--rex-misc-color-sky-blue) !important;

    .mdc-evolution-chip__text-label {
      color: var(--rex-misc-color-blue) !important;
    }
  }

  &.mat-chip-green {
    border: 1px solid var(--rex-semantic-color-green-light-6) !important;
    background: var(--rex-semantic-color-green-light-9) !important;

    .mdc-evolution-chip__text-label {
      color: var(--rex-semantic-color-green-light-6) !important;
    }
  }

  &.mat-chip-solid-green {
    background: var(--rex-primary-color-800) !important;

    .mdc-evolution-chip__text-label {
      color: var(--rex-background-color-1) !important;
    }
  }

  &.mat-chip-strech-green {
    background: var(--rex-misc-color-green) !important;
    padding: 1px 4px !important;
    height: auto !important;
    width: -moz-fit-content !important;
    width: fit-content !important;

    .mdc-evolution-chip__text-label {
      color: var(--rex-background-color-1) !important;
      font-size: 8px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }
  }
}

.mat-mdc-standard-chip {
  z-index: 0;
  width: auto !important; // 99px
  height: 20px !important;
}

mat-slide-toggle {
  &.mat-primary {
    .mdc-switch {
      width: 34px;

      &:enabled {
        .mdc-switch__track {
          border-radius: 14px;

          &::before {
            background: linear-gradient(0deg, rgba(254, 252, 252, 0.60) 0%, rgba(254, 252, 252, 0.60) 100%), var(--rex-accent-color-200) !important;
          }

          &::after {
            background: linear-gradient(0deg, rgba(254, 252, 252, 0.60) 0%, rgba(254, 252, 252, 0.60) 100%), var(--rex-primary-color-200) !important;
          }
        }
      }

      &:disabled {
        .mdc-switch__track {
          opacity: 1;

          &::before {
            background: var(--rex-accent-color-200) !important;
          }

          &::after {
            background: var(--rex-accent-color-200);
          }
        }
      }

      .mdc-switch__handle {
        .mdc-switch__icons {
          display: none;
        }
      }

      &.mdc-switch--selected {
        &:enabled {
          .mdc-switch__handle::after {
            background: var(--rex-primary-color-500) !important;
          }
        }

        &:disabled {
          .mdc-switch__handle::after {
            background: var(--rex-accent-color-50) !important;
          }
        }
      }

      &.mdc-switch--unselected {
        &:enabled {
          .mdc-switch__handle::after {
            background: var(--rex-accent-color-50) !important;
          }
        }

        &:disabled {
          .mdc-switch__handle::after {
            background: var(--rex-accent-color-50) !important;
            opacity: 1;
          }
        }
      }
    }
  }
}

/* SCROLL OVERRIDES */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: $scroll-background;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: $scroll-background;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $scroll-color;
}

/* TOASTER STYLES */
.tracking-toastr-information {
  max-width: 760px;

  .swal2-popup.swal2-toast .swal2-html-container {
    margin: 0em 0.8em;
  }

  .toast-info {
    border: var(--rex-toastr-color-blue-1) 1px solid !important;
    border-radius: 8px !important;
    background-color: var(--rex-toastr-color-blue-2) !important;
    padding: 16px !important;

    nb-icon,
    mat-icon {
      color: var(--rex-toastr-color-blue-1) !important;
    }

    .message-title {
      color: var(--rex-toastr-color-blue-1) !important;
      font-family: 'Kanit';
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }

    .message-body {
      color: var(--rex-toastr-color-blue-1) !important;
      font-family: 'Kanit';
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }
  }

  .toast-succcess {
    border: var(--rex-toastr-color-green-1) 1px solid !important;
    border-radius: 8px !important;
    background-color: var(--rex-toastr-color-green-2) !important;
    padding: 16px;

    nb-icon,
    mat-icon {
      color: var(--rex-toastr-color-green-1) !important;
    }

    .message-title {
      color: var(--rex-toastr-color-green-1) !important;
      font-family: 'Kanit';
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }

    .message-body {
      color: var(--rex-toastr-color-green-1) !important;
      font-family: 'Kanit';
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }
  }

  .toast-warning {
    border: var(--rex-toastr-color-yellow-1) 1px solid !important;
    border-radius: 8px !important;
    background-color: var(--rex-toastr-color-yellow-2) !important;
    padding: 16px !important;

    nb-icon,
    mat-icon {
      color: var(--rex-toastr-color-yellow-1) !important;
    }

    .message-title {
      color: var(--rex-toastr-color-yellow-1) !important;
      font-family: 'Kanit';
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }

    .message-body {
      color: var(--rex-toastr-color-yellow-1) !important;
      font-family: 'Kanit';
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }
  }

  .toast-error {
    border: var(--rex-toastr-color-red-1) 1px solid !important;
    border-radius: 8px !important;
    background-color: var(--rex-toastr-color-red-3) !important;
    padding: 16px;

    nb-icon,
    mat-icon {
      color: var(--rex-toastr-color-red-2) !important;
    }

    .message-title {
      color: var(--rex-toastr-color-red-2) !important;
      font-family: 'Kanit';
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }

    .message-body {
      color: var(--rex-toastr-color-red-2) !important;
      font-family: 'Kanit';
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important;
    }
  }
}

.tracking-toastr-confirmation {
  .message-body {
    color: var(--rex-misc-color-blue) !important;
    font-family: 'Kanit' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    text-align: center !important;
    margin: 0px !important;
  }

  .swal2-actions button {
    margin: 0px 10px !important;
  }

  .swal2-close {
    color: var(--rex-misc-color-blue) !important;
  }

  .swal2-close:hover {
    color: var(--rex-misc-color-blue) !important;
  }

  .swal2-popup {
    padding: 15px 15px 35px 15px;
  }
}

//SPAN CHIP TICKET STATUS
.mat-chip-ticket-status-1 {
  border-radius: 4px !important;
  width: 80% !important;
  background-color: var(--rex-misc-color-red-5) !important;

  .mdc-evolution-chip__action--primary {
    width: 100% !important;
  }

  .mdc-evolution-chip__text-label {
    color: var(--rex-misc-color-red-6) !important;
    text-align: center !important;
    font-size: 14px !important;
    font-family: 'Kanit' !important;
  }
}

.mat-chip-ticket-status-2 {
  border-radius: 4px !important;
  width: 80% !important;
  background-color: var(--rex-misc-color-yellow-1) !important;

  .mdc-evolution-chip__action--primary {
    width: 100% !important;
  }

  .mdc-evolution-chip__text-label {
    color: var(--rex-misc-color-yellow-2) !important;
    text-align: center !important;
    font-size: 14px !important;
    font-family: 'Kanit' !important;
  }
}

.mat-chip-ticket-status-3 {
  border-radius: 4px !important;
  width: 80% !important;
  background-color: var(--rex-misc-color-blue-2) !important;

  .mdc-evolution-chip__action--primary {
    width: 100% !important;
  }

  .mdc-evolution-chip__text-label {
    color: var(--rex-misc-color-blue-3) !important;
    text-align: center !important;
    font-size: 14px !important;
    font-family: 'Kanit' !important;
  }
}

.mat-chip-ticket-status-4 {
  border-radius: 4px !important;
  width: 80% !important;
  background-color: var(--rex-semantic-color-green-light-7) !important;

  .mdc-evolution-chip__action--primary {
    width: 100% !important;
  }

  .mdc-evolution-chip__text-label {
    color: var(--rex-semantic-color-green-light-8) !important;
    text-align: center !important;
    font-size: 14px !important;
    font-family: 'Kanit' !important;
  }
}

.mat-chip-ticket-status-5 {
  border-radius: 4px !important;
  width: 80% !important;
  background-color: var(--rex-misc-color-brown-1) !important;

  .mdc-evolution-chip__action--primary {
    width: 100% !important;
  }

  .mdc-evolution-chip__text-label {
    color: var(--rex-misc-color-brown-2) !important;
    text-align: center !important;
    font-size: 14px !important;
    font-family: 'Kanit' !important;
  }
}

.mat-chip-ticket-status-6 {
  border-radius: 4px !important;
  width: 80% !important;
  background-color: var(--rex-semantic-color-green-light-10) !important;

  .mdc-evolution-chip__action--primary {
    width: 100% !important;
  }

  .mdc-evolution-chip__text-label {
    color: var(--rex-system-color-success) !important;
    text-align: center !important;
    font-size: 14px !important;
    font-family: 'Kanit' !important;
  }
}

.mat-chip-tag {
  border-radius: 4px !important;
  width: auto !important;
  background-color: #424DB3 !important;
  padding: 0 4px !important;
  // margin: 4px !important;

  .mdc-evolution-chip__action--primary {
    width: auto !important;
  }

  .mdc-evolution-chip__text-label {
    color: #FFFFFF !important;
    font-weight: 400;
    text-transform: uppercase !important;
    text-align: center !important;
    font-size: 9px !important;
    font-family: 'Kanit' !important;
  }

  .mat-icon {
    font-size: 10px !important;
    height: 10px !important;
    width: 10px !important;
    padding: 0 0 0 4px !important;
    color: #FFF !important;
    margin-left: 0 !important;
  }
}

.mat-form-field-reassign {
  .mdc-evolution-chip-set__chips {
    margin-left: 0 !important;
  }
}

.mat-chip-reassign {
  background-color: #F6F8FC !important;
  padding: 2px 8px 2px 2px !important;
  box-sizing: content-box;
  border-radius: 100px !important;
  margin-left: 0 !important;
  min-height: 22px !important;

  .mdc-evolution-chip__text-label {
    color: #8A8A8A !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    display: flex;
    align-items: center;
  }

  .mat-icon {
    font-size: 8px !important;
    height: 8px !important;
    width: 8px !important;
    color: #424DB3 !important;
    padding-right: 0px !important;
  }

  &__profile {
    color: #FFF;
    text-align: center;
    font-size: 8.371px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 100px;
    background-color: #FE9539;
    border: 1px solid #FFF;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .mat-mdc-chip-remove {
    opacity: 1 !important;
  }
}

.mat-autocomplete-reassign {
  .mat-mdc-optgroup-label {
    color: #D0D0D0;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    min-height: 36px;
  }

  .mat-mdc-option {
    padding-left: 16px !important;
    min-height: 36px !important;
  }

  &__option {
    display: flex !important;

    &__profile {
      color: #FFF;
      text-align: center;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 100px;
      background-color: #FE9539;
      border: 1px solid #FFF;
      width: 24px;
      height: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 8px;
    }

    &__name {
      color: #424DB3;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 120% */
    }

    &__email {
      color: #637AD2;
      font-size: 11px;
      font-style: normal;
      font-weight: 300;
      line-height: 12px; /* 150% */
    }
  }
  
}

mat-datepicker-content {
  .mat-calendar {
    height: 370px !important;
  }

  .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    color: var(--rex-accent-color-600);
    font-style: normal;
    font-weight: 300;
    text-align: center;
    &.mat-calendar-body-today {
      color: var(--rex-primary-color-500);
    }
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: var(--rex-primary-color-500);
  }
}

mat-tab-header {
  .mat-mdc-tab {
    .mdc-tab__text-label {
      color: var(--rex-black-color-400) !important;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    &:not(.mat-mdc-tab-disabled) {
      .mdc-tab-indicator__content--underline {
        border-color: var(--rex-secondary-color-300) !important;
      }

      &.mdc-tab--active .mdc-tab__text-label {
        color: var(--rex-secondary-color-300) !important;
      }
    }
    .mat-ripple-element {
      background-color: var(--rex-secondary-color-50) !important;
    }
    .mdc-tab__ripple::before {
      background-color: var(--rex-secondary-color-50) !important;
    }   
  }

  .mat-mdc-tab-header-pagination {
    .mat-mdc-tab-header-pagination-chevron {
      border-color: var(--rex-secondary-color-300);
    }
    .mat-ripple-element {
      background-color: var(--rex-secondary-color-100) !important;
    }
  }
}

mat-tab-body {
  .mat-mdc-tab-body-content {
    overflow: hidden;
  }
}

mat-checkbox.checkbox-header {
  --mdc-checkbox-state-layer-size: 32px;
}

mat-button-toggle-group {
  --mat-standard-button-toggle-shape: 49px;
  --mat-standard-button-toggle-selected-state-background-color: var(--rex-primary-color-500);
  --mat-standard-button-toggle-text-color: var(--rex-black-color-600);
  --mat-standard-button-toggle-selected-state-text-color: var(--rex-background-color-1);

  &.mat-button-toggle-group-appearance-standard {
    border: none !important;
    background-color: var(--rex-background-color-1);
  }

  .mat-button-toggle-appearance-standard {
    border-radius: 49px;
    border-left: none !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    min-width: 100px;
  }
}

.mat-mdc-radio-group  {
  &.color_secondary .mat-mdc-radio-button.mat-primary {
    --mdc-radio-selected-focus-icon-color: var(--rex-secondary-color-300);
    --mdc-radio-selected-hover-icon-color: var(--rex-secondary-color-300);
    --mdc-radio-selected-icon-color: var(--rex-secondary-color-300);
    --mdc-radio-selected-pressed-icon-color: var(--rex-secondary-color-300);
    --mat-radio-checked-ripple-color: var(--rex-secondary-color-300);
  }
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: var(--rex-black-color-300) !important;
  background-color: var(--rex-black-color-300) !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
 border-color: var(--rex-primary-color-800) !important; 
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  border-color: var(--rex-primary-color-800) !important;
  background-color: var(--rex-primary-color-800) !important;
}

.mat-mdc-paginator-page-size-label,
.mat-mdc-paginator-range-label {
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  color: var(--rex-black-color-500);
}

.edit-request-form__panel {
  .mat-expansion-panel-body {
    padding: 24px 0 16px !important;
  }

  .mat-expansion-indicator::after {
    color: #637AD2;
  }
}